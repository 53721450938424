import request from '../utils/request';

// 获取订阅计划列表
export async function getPlanList() {
    try {
        return await request({
            url: `/subscriptions/getSubscriptions`,
            method: 'post'
        });
    } catch (error) {
        return {};
    }
}

// 获取价格
export async function getPrices(data) {
    try {
        return await request({
            url: `/subscriptions/getPrice`,
            method: 'post',
            data
        });
    } catch (error) {
        return {};
    }
}

//获取planId
export async function getPlanId(data) {
    try {
        return await request({
            url: `/order/payPalPlan`,
            method: 'post',
            data
        });
    } catch (error) {
        return {};
    }
}

// 保存subscriptionID
export async function saveSubscriptionId(data) {
    try {
        return await request({
            url: `/order/setSubscriptions`,
            method: 'post',
            data
        });
    } catch (error) {
        return {};
    }
}

// 获取订阅列表
export async function getSubscriptionList() {
    try {
        return await request({
            url: `/subscriptions`,
            method: 'get',
        });
    } catch (error) {
        return {};
    }
}

//取消订阅
export async function cancelSubscriptions(id) {
    try {
        return await request({
            url: `/order/cancelSubscriptions/${id}`,
            method: 'post',
        });
    } catch (error) {
        return {};
    }
}

export async function getMinimumPrice() {
    try {
        return await request({
            url: `/subscriptions/getMinimumPrice`,
            method: 'get',
        });
    } catch (error) {
        return {};
    }
}
export async function getLeetCodeClasses() {
    try {
        return await request({
            url: `/class/2`,
            method: 'get',
        });
    } catch (error) {
        return {};
    }
}