<template>
  <div id="subscribe">
    <div class="recommend">
      <div class="container">
        <div class="title">为你推荐 <span>RECOMMEND</span></div>
        <div class="subtitle">LeetCode千题视频讲解会员，找到更好的工作</div>
        <div class="plans">
          <!-- <div class="plan">
            <img src="@/assets/img/subscribe/hot.png" class="tip" alt="" />
            <div class="duration">每月</div>
            <div class="duration-translate">EVERY MONTH</div>
            <div class="innerplan">
              <div class="planName">LeetCode 1-300题</div>
              <div class="planPrice">
                <span>$</span><span class="priceCount">59</span
                ><span>/ 月</span>
              </div>
            </div>
            <div class="innerplan">
              <div class="planName">LeetCode 1-600题</div>
              <div class="planPrice">
                <span>$</span><span class="priceCount">79</span
                ><span>/ 月</span>
              </div>
            </div>
            <div class="innerplan">
              <div class="planName">LeetCode 1-1000题</div>
              <div class="planPrice">
                <span>$</span><span class="priceCount">99</span
                ><span>/ 月</span>
              </div>
            </div>
            <el-button class="buyButton" @click="buyNow">查看详情</el-button>
          </div> -->
          <div class="plan">
            <img src="@/assets/img/subscribe/sale.png" class="tip" alt="" />
            <div class="duration">三个月</div>
            <div class="duration-translate">THREE MONTHS</div>
            <div class="descriptions">
              <div class="description">LeetCode 1-300题</div>
              <div class="description">无分类顺序表</div>
              <div class="description">三种语言代码</div>
              <div class="description">Java/C++/Python</div>
            </div>
            <div class="planPrice">
              <span>$</span
              ><span class="priceCount">{{
                comboData[0] && comboData[0].usdprice
              }}</span>
            </div>
            <!-- <div class="originPrice">
              原价：${{ comboData[0] && comboData[0].usdprice }}
            </div> -->
            <el-button
              class="buyButton"
              style="margin-top: 16px"
              @click="buyNow(comboData[0])"
              >立即购买</el-button
            >
          </div>
          <div class="plan">
            <img src="@/assets/img/subscribe/sale.png" class="tip" alt="" />
            <div class="duration">三个月</div>
            <div class="duration-translate">THREE MONTHS</div>
            <div class="descriptions">
              <div class="description">LeetCode 1-600题</div>
              <div class="description">LeetCode 600题分类顺序表</div>
              <div class="description">三种语言代码</div>
              <div class="description">Java/C++/Python</div>
            </div>
            <div class="planPrice">
              <span>$</span
              ><span class="priceCount">{{
                comboData[1] && comboData[1].usdprice
              }}</span>
            </div>
            <!-- <div class="originPrice">
              原价：${{ comboData[1] && comboData[1].usdprice }}
            </div> -->
            <el-button
              class="buyButton"
              style="margin-top: 16px"
              @click="buyNow(comboData[1])"
              >立即购买</el-button
            >
          </div>
          <div class="plan">
            <img src="@/assets/img/subscribe/sale.png" class="tip" alt="" />
            <div class="duration">半年</div>
            <div class="duration-translate">SIX MONTHS</div>
            <div class="descriptions">
              <div class="description">LeetCode 1-1000题</div>
              <div class="description">LeetCode 600题分类顺序表</div>
              <div class="description">三种语言代码</div>
              <div class="description">Java/C++/Python</div>
            </div>
            <div class="planPrice">
              <span>$</span
              ><span class="priceCount">{{
                comboData[2] && comboData[2].usdprice
              }}</span>
            </div>
            <!-- <div class="originPrice">
              原价：${{ comboData[2] && comboData[2].usdprice }}
            </div> -->
            <el-button
              class="buyButton"
              style="margin-top: 16px"
              @click="buyNow(comboData[2])"
              >立即购买</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div class="other">
      <div class="container">
        <div class="title">其他服务供你选择 <span>OTHER SERVICE</span></div>
        <div class="choices">
          <div class="choice">
            <img class="icon" src="@/assets/img/subscribe/icon1.png" alt="" />
            <div class="iconDes">LeetCode 600题分类顺序表</div>
          </div>

          <div class="choice">
            <img class="icon" src="@/assets/img/subscribe/icon4.png" alt="" />
            <div class="iconDes">思路点评 &amp; 高频分析</div>
          </div>

          <div class="choice">
            <img class="icon" src="@/assets/img/subscribe/icon2.png" alt="" />
            <div class="iconDes">三种语言代码(Java/C++/Python)</div>
          </div>
          <div class="choice">
            <img class="icon" src="@/assets/img/subscribe/icon3.png" alt="" />
            <div class="iconDes">微信群答疑</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getLeetCodeClasses } from "@/service/subscribe";
import { Toast } from "vant";
import { setStorageForArray } from "@/utils/index";
export default {
  name: "LeetCodeSubscribe",
  data() {
    return {
      comboData: [],
    };
  },
  methods: {
    buyNow(item) {
      setStorageForArray("order", [item]);
      this.$router.push("/pay/confirm");
    },
  },
  mounted() {
    getLeetCodeClasses().then((res) => {
      if (res.success) {
        this.comboData = res.result.courseCombosList;
      }
    });
  },
};
</script>
<style scoped lang="scss">
#subscribe {
  padding-top: 30px;
}
.banner {
  width: 100%;
  position: relative;
  img {
    vertical-align: top;
    width: 100%;
  }
  .banner-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 28px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #ffffff;
    line-height: 26px;
  }
}
.recommend {
  padding: 50px 0;
  width: 100%;
  background: #f8f8f8;
  .container {
    width: 100%;
    margin: 0 auto;
    .title {
      font-size: 16px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.800000011920929);
      line-height: 16px;
      margin-bottom: 20px;
      padding-left: 20px;
      span {
        font-size: 14px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.5);
        line-height: 14px;
      }
    }
    .subtitle {
      font-size: 12px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      line-height: 12px;
      padding-left: 20px;
    }
    .plans {
      margin-top: 38px;
      text-align: center;
      .plan {
        width: 335px;
        background: #ffffff;
        border-radius: 8px;
        text-align: center;
        padding: 68px 0;
        position: relative;
        margin: 0 auto;
        margin-bottom: 20px;
        .tip {
          position: absolute;
          left: 0;
          top: 0;
          width: 80px;
        }
        .duration {
          font-size: 28px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #999999;
          line-height: 42px;
        }
        .duration-translate {
          padding-top: 14px;
          padding-bottom: 36px;
          font-size: 14px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: rgba(120, 120, 128, 0.36);
          line-height: 21px;
        }
        .innerplan {
          margin-bottom: 24px;
        }
        .planName {
          font-size: 14px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.4);
          line-height: 21px;
          margin-bottom: 10px;
        }
        .planPrice {
          font-size: 14px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.8);
          line-height: 21px;
          color: #e59f3c;
          .priceCount {
            font-size: 28px;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            font-weight: 500;

            line-height: 42px;
            position: relative;
          }
        }
        .originPrice {
          text-decoration: line-through;
          font-size: 14px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.4);
          line-height: 21px;
        }
        .descriptions {
          margin-bottom: 12px;
          font-size: 14px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.4);
          line-height: 40px;
        }
        .buyButton {
          background: #f3bb6c;
          border-radius: 4px;
          color: #fff;
          padding: 10px 72px;
        }
      }
    }
  }
}
.other {
  padding-top: 30px;
  padding-bottom: 20px;
  background: #f2f2f2;
  .container {
    width: 100%;
    margin: 0 auto;
    .title {
      font-size: 16px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.800000011920929);
      line-height: 16px;
      margin-bottom: 20px;
      padding-left: 20px;
      span {
        font-size: 14px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.5);
        line-height: 14px;
      }
    }
    .choices {
      margin-top: 20px;

      .choice {
        width: 335px;
        height: 148px;
        background: #ffffff;
        border-radius: 8px;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 20px;
        .icon {
          width: 40px;
          height: 40px;
          margin-top: 42px;
          margin-bottom: 20px;
        }
        .iconDes {
          font-size: 14px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #e59f3c;
          line-height: 21px;
        }
      }
    }
  }
}
::v-deep .choice-container {
  padding: 20px;
  img {
    width: calc(100% - 40px);
  }
}
.hoverText {
  font-size: 12px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
  line-height: 16px;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 12px;
}
</style>